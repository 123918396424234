<template>
  <div>
    <b-row
      v-for="(row,index) in inputs"
      :key="index"
    >
      <b-col
        v-for="(singleInput,loopIndex) in row.singleRow.rowInputs"
        :key="loopIndex"
        :md="singleInput.md"
      >
        <GlobalSingleInput
          :key="loopIndex"
          :input="singleInput"
        />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import { reactive, watch } from '@vue/composition-api'
import GlobalSingleInput from '@/views/components/form/GlobalSingleInput.vue'
import { convertInputsToKeyValuePair } from '@/helpers/objectHelper'
import _ from 'lodash'
import i18n from '@/libs/i18n'

export default {
  name: i18n.t('label.identification'),
  components: {
    GlobalSingleInput,
  },
  props: ['data', 'metadata'],
  setup(props) {
    const inputs = reactive([
      {
        singleRow:
            {
              rowAttributes: {},
              rowInputs: [
                {
                  md: 6,
                  label: i18n.t('label.Name'),
                  type: 'text',
                  key: 'name',
                  rules: 'required',
                  placeholder: i18n.t('placeholder.monthly_invoicing'),
                  value: '',
                },
                {
                  md: 6,
                  label: i18n.t('label.code'),
                  type: 'text',
                  key: 'code',
                  rules: '',
                  placeholder: i18n.t('placeholder.PR021'),
                  value: '',
                },
              ],
            },
      },
      {
        singleRow:
            {
              rowAttributes: {},
              rowInputs: [
                {
                  md: 6,
                  label: i18n.t('label.require_image'),
                  type: 'select',
                  key: 'requireImage',
                  rules: 'required',
                  placeholder: i18n.t('label.select_yes_no'),
                  value: { value: false, title: i18n.t('message.no') },
                  options: [{ value: true, title: i18n.t('message.yes') }, { value: false, title: i18n.t('message.no') }],
                  multiple: false,
                },
                {
                  md: 6,
                  label: i18n.t('label.require_metadata'),
                  type: 'select',
                  key: 'metadata',
                  rules: '',
                  placeholder: i18n.t('label.select_if_applicable'),
                  value: [],
                  options: props.metadata.value,
                  multiple: true,
                },
              ],
            },
      },
      {
        singleRow:
            {
              rowAttributes: {},
              rowInputs: [
                {
                  md: 6,
                  label: i18n.t('label.goal'),
                  type: 'textarea',
                  key: 'goal',
                  rules: '',
                  placeholder: i18n.t('placeholder.monthly_invoicing'),
                  value: '',
                  description: '',
                },
                // {
                //   md: 6,
                //   label: i18n.t('label.assumptions'),
                //   type: 'textarea',
                //   key: 'assumptions',
                //   rules: '',
                //   placeholder: i18n.t('placeholder.monthly_invoicing'),
                //   value: '',
                //   description: '',
                // },
              ],
            },
      },
    ])

    const getData = () => ({ identification: convertInputsToKeyValuePair(inputs) })

    watch(() => props.metadata, data => {
      inputs[1].singleRow.rowInputs[1].options = data.value
    }, { deep: true, immediate: true })

    watch(() => props.data, data => {
      if (data) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(data)) {
          inputs.forEach(row => {
            if (_.find(row.singleRow.rowInputs, { key })) {
              _.find(row.singleRow.rowInputs, { key }).value =
                value === true
                  ? { value: true, title: i18n.t('message.yes') }
                  : value === false
                    ? { value: false, title: i18n.t('message.no') }
                    : value
            }
          })
        }
      }
    }, { deep: true, immediate: true })

    return {
      inputs,
      getData,
    }
  },
}
</script>

<style scoped>

</style>
