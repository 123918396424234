<template>
  <div>
    <b-form-group
      :label="input.label"
      :label-for="`v-${input.label}`"
      :description="input.description"
    >
      <validation-provider
        #default="{ errors }"
        :name="input.label"
        :rules="input.rules"
      >
        <b-form-input
          v-if="input.type === 'text'"
          :id="input.key"
          v-model="input.value"
          :state="errors.length > 0 ? false:null"
          :placeholder="input.placeholder"
        />

        <v-select
          v-if="input.type === 'select'"
          :id="input.key"
          v-model="input.value"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :multiple="input.multiple"
          label="title"
          :options="input.options"
          :placeholder="input.placeholder"
        />

        <b-form-textarea
          v-if="input.type === 'textarea'"
          :id="input.key"
          v-model="input.value"
          rows="3"
          :placeholder="input.placeholder"
        />

        <quill-editor
          v-if="input.type === 'quill'"
          :id="input.key"
          v-model="input.value"
          :placeholder="input.placeholder"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

export default {
  name: 'GlobalSingleInput',
  components: { ValidationProvider, vSelect, quillEditor },
  props: {
    input: { type: Object, required: true },
  },
  setup() {
    return {
      required,
    }
  },
}
</script>

<style scoped>

</style>
