<template>
  <div>
    <b-row>
      <b-col
        class="mb-2"
        cols="12"
      >
        <h5 class="mb-0">
          {{ $t('message.activity_title') }}
          <br>
          <small
            class="text-muted"
          >{{ $t('message.activity_desc') }}
          </small>
        </h5>
      </b-col>
    </b-row>
    <b-row
      v-for="(row, index) in descriptionInput"
      :key="index"
    >
      <b-col
        v-for="(singleInput, loopIndex) in row.singleRow.rowInputs"
        :key="loopIndex"
        :md="singleInput.md"
      >
        <GlobalSingleInput :input="singleInput" />
      </b-col>
    </b-row>
    <div>
      <b-form
        ref="form"
        class="repeater-form"
        @submit.prevent="repeatAgain"
      >
        <b-row
          v-for="(row, index) in items"
          v-if="!row.singleRow.rowInputs[0].deleted"
          :key="index"
          ref="row"
        >
          <b-col
            v-for="(singleInput, loopIndex) in row.singleRow.rowInputs"
            :key="loopIndex"
            :md="singleInput.md"
          >
            <GlobalSingleInput
              :key="loopIndex"
              :input="singleInput"
            />
          </b-col>

          <b-col
            v-if="index > 0"
            lg="4"
            md="4"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>{{ $t('message.delete') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      :class="{ 'mt-2': items.length > 0 }"
      variant="primary"
      @click="repeatAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>{{ $t('message.Add_New_Activity') }}</span>
    </b-button>
  </div>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import GlobalSingleInput from '@/views/components/form/GlobalSingleInput.vue'
import { convertInputsToKeyValuePair } from '@/helpers/objectHelper'
import i18n from '@/libs/i18n'
import { set } from '@vue/composition-api'

export default {
  directives: {
    Ripple,
  },
  components: {
    GlobalSingleInput,
  },
  mixins: [heightTransition],
  props: {
    // eslint-disable-next-line vue/require-valid-default-prop
    data: { type: Object, default: {} },
  },
  data() {
    return {
      descriptionInput: [
        {
          singleRow: {
            rowAttributes: {},
            rowInputs: [
              {
                label: i18n.t('label.description'),
                type: 'textarea',
                key: 'description',
                rules: '',
                placeholder: i18n.t('placeholder.activity_desc'),
                value: '',
                description: i18n.t('message.process_desc'),
              },
            ],
          },
        },
      ],
      items: [
        {
          singleRow: {
            rowAttributes: {},
            rowInputs: [
              {
                md: 8,
                label: i18n.t('message.activity_no_1'),
                type: 'text',
                key: 'activity_1',
                rules: 'required',
                placeholder: i18n.t('placeholder.activity'),
                value: '',
              },
            ],
          },
        },
      ],
    }
  },
  watch: {
    data: {
      handler(value) {
        this.descriptionInput[0].singleRow.rowInputs[0].value = value.description
        if (value.list) {
          this.items = []
          value.list.forEach((activity, index) => {
            this.repeatAgain()
            this.items[index].singleRow.rowInputs[0].value = activity
          })
          this.trAddHeight(value.list.length * 40)
        }
      },
      deep: true,
      immediate: true,
    },
    items: {
      handler(value) {
        this.$emit('updActivities', value)
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeatAgain() {
      this.items.push({
        singleRow: {
          rowAttributes: {},
          rowInputs: [
            {
              md: 8,
              label: i18n.tc('message.activity_no', 0, {count: `${this.items.length + 1}`}),
              type: 'text',
              key: `activity_${this.items.length + 1}`,
              rules: 'required',
              placeholder: i18n.t('placeholder.activity'),
              value: '',
            },
          ],
        },
      })

      this.$nextTick(() => {
        if (this.items.length === 1) { this.trAddHeight(this.$refs.row[0].offsetHeight + 10) } else this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      set(this.items[index].singleRow.rowInputs[0], 'deleted', true)
      if (this.items.length === 0) this.initTrHeight()
      else this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(
          this.$refs.form.scrollHeight + this.items.length === 0 ? 0 : 90,
        )
      })
    },
    getData() {
      const itemsFiltered = this.items.filter(e => !e.singleRow.rowInputs[0].deleted)
      return {
        activities: {
          ...convertInputsToKeyValuePair(this.descriptionInput),
          list: Object.values(convertInputsToKeyValuePair(itemsFiltered)),
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
