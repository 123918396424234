<template>
  <div>
    <b-row>
      <b-col
        class="mb-2"
        cols="12"
      >
        <h5 class="mb-0">
          {{ $t('message.behaviour_title') }}
          <br>
          <small
            class="text-muted"
          >{{ $t('message.behaviour_desc') }}
          </small>
        </h5>
      </b-col>
    </b-row>
    <div>
      <b-form
        ref="form"
        class="repeater-form"
        @submit.prevent="repeatAgain"
      >
        <b-row
          v-for="(row, index) in items"
          v-if="!row.deleted"
          :key="index"
          ref="row"
          class="border-bottom mb-1"
        >
          <b-col md="12">
            <h6>
              {{ $t('label.activity') }} :
              <span
                class="text-primary font-medium-3"
              >{{ row.mainTitle }}
              </span>
            </h6>
          </b-col>

          <b-col md="12">
            <b-row
              v-for="(singleRowInputs, loopIndex) in row.singleRow"
              :key="loopIndex"
            >
              <b-col
                v-for="(input, colIndex) in singleRowInputs.rowInputs"
                :key="colIndex"
                :md="input.md"
              >
                <GlobalSingleInput
                  :key="colIndex"
                  :input="input"
                />
              </b-col>
              <b-col
                v-if="loopIndex > 0"
                md="2"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index, loopIndex)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                  <span>{{ $t('message.delete') }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col mf="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :class="{ 'mb-2': items.length > 0 }"
              variant="primary"
              @click="repeatAgain(index, row.mainTitle)"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>{{ $t('message.Add_New_Behaviour_to_Activity') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import GlobalSingleInput from '@/views/components/form/GlobalSingleInput.vue'
import _ from 'lodash'
import constants from '../../../../constants'
import i18n from '@/libs/i18n'

export default {
  directives: {
    Ripple,
  },
  components: {
    GlobalSingleInput,
  },
  mixins: [heightTransition],
  props: {
    // eslint-disable-next-line vue/require-valid-default-prop
    data: { type: Array, default: [] },
    // eslint-disable-next-line vue/require-valid-default-prop
    addedActivities: { type: Array, default: [] },
  },
  data() {
    return {
      items: [],
    }
  },
  watch: {
    /* data(value) {
      if (value.length) {
        value.forEach(val => {
          setTimeout(log)
          const index = _.find(this.items, { mainTitle: val.activity })
          console.log('index'.index)
        /!*  val.behaviours.forEach(behaviour => {
            this.repeatAgain(index, behaviour, val.activity)
          })*!/
        //  this.repeatAgain(qualification)
        })
        /!* this.items.forEach((item, index) => {
          this.items[index].singleRow.rowInputs[0].value = value[index]
        })
        this.trAddHeight(value.length * 90) *!/
      }
    }, */
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeatAgain(index, activity, value = null, typeValue = null) {
      let { length } = this.items[index].singleRow
      // eslint-disable-next-line no-plusplus
      length = ++length
      this.items[index].singleRow.push({
        rowInputs: [
          {
            md: 7,
            label: i18n.tc('message.behaviour_no', 0, {count: `${index + 1}.${length}`}),
            type: 'quill',
            key: `behaviour_${index + 1}.${length}`,
            rules: 'required',
            placeholder: i18n.t('placeholder.activity'),
            value: value ?? '',
          },
          {
            md: 3,
            label: i18n.tc('message.type_no', 0, {count: `${index + 1}.${length}`}),
            type: 'select',
            key: `behaviour_${index + 1}.${length}_type`,
            rules: 'required',
            options: constants.types,
            placeholder: i18n.t('placeholder.type'),
            value: typeValue ?? '',
          },
        ],
      })

      this.$nextTick(() => {
        this.trAddHeight(100)
      })
    },
    removeItem(index, itemIndex) {
      this.items[index].singleRow.splice(itemIndex, 1)
      this.trAddHeight(-100)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        let totalRows = 0
        // eslint-disable-next-line no-return-assign
        this.items.forEach(item => (totalRows += item.singleRow.length))
        this.trSetHeight(
          this.$refs.form.scrollHeight
            + totalRows * 120
            + this.items.length * 50,
        )
      })
    },
    getData() {
      const behaviours = []

      // eslint-disable-next-line array-callback-return
      this.items.map(item => {
        if (!item.deleted) {
          const temFormat = {
            activity: item.mainTitle,
            behaviours: item.singleRow.map(singleRow => ({
              type: singleRow.rowInputs[1].value?.value,
              name: singleRow.rowInputs[0].value,
            })),
          }
          behaviours.push(temFormat)
        }
      })
      return { behaviours }
    },
    async createForm() {
      const itemsCount = this.items.length
      this.addedActivities.forEach((activity, index) => {
        if (this.items[index]) {
          this.items[index].mainTitle = activity.singleRow.rowInputs[0].value
          this.items[index].deleted = activity.singleRow.rowInputs[0].deleted
        } else {
          const payload = {
            mainTitle: activity.singleRow.rowInputs[0].value,
            deleted: activity.singleRow.rowInputs[0].deleted,
            singleRow: [
              {
                rowAttributes: {},
                rowInputs: [
                  {
                    md: 7,
                    label: i18n.tc('message.behaviour_no', 0, {count: `${index + 1}.1`}),
                    type: 'quill',
                    key: `behaviour_${index + 1}.1`,
                    rules: 'required',
                    placeholder: i18n.t('placeholder.activity'),
                    value: '',
                  },
                  {
                    md: 3,
                    label: i18n.tc('message.type_no', 0, {count: `${index + 1}.1`}),
                    type: 'select',
                    key: `behaviour_${index + 1}.1_type`,
                    rules: 'required',
                    options: constants.types,
                    placeholder: i18n.t('placeholder.type'),
                    value: '',
                  },
                ],
              },
            ],
          }
          this.items.push(payload)
        }
      })
      if (!itemsCount && this.data.length > 0) {
        this.data.forEach((val, idx) => {
          if (!this.items[idx].deleted) {
            this.items[idx].singleRow = []
            val.behaviours.forEach(behaviour => {
              this.repeatAgain(
                idx,
                val.activity,
                behaviour.name,
                _.find(constants.types, { value: behaviour.type }),
              )
            })
          }
        })
      }
      // if (this.addedActivities.length !== this.items.length) {
      //   this.items = this.items.filter(row => this.addedActivities.includes(row.mainTitle))
      // }
      this.initTrHeight()
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
