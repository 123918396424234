import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useProcessEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router, route } = useRouter()
  const { getItemsWithAggregate, updateItem, ObjectId } = realmConnection()
  const item = ref(null)
  const isSubmitting = ref(false)
  const query = { _id: ObjectId(route.value.params.id) }
  const collection = 'process'

  const show = async () => {
    isSubmitting.value = true

    try {
      const pipeline = [
        { $match: query },
        { $lookup: { from: 'metadata', localField: 'metadata', foreignField: '_id', pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ], as: 'metadataData' } },
      ]
    
      const items = await getItemsWithAggregate({ collection, pipeline })
      if (!items?.[0]) throw new Error('Item not found')

      items[0].metadata = items[0].metadataData?.map(({ name, _id }) => ({ title: i18n.t(`metadata.${name}`), value: _id })) || []
      item.value = items[0]
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.data_fetch_error'))
    } finally {
      isSubmitting.value = false
    }
  }

  const update = async payload => {
    isSubmitting.value = true

    payload.metadata = payload.metadata?.map(e => ObjectId(e)) || []

    try {
      const action = { $set: payload }

      await updateItem({ collection, query, action })

      showSuccessMessage(i18n.t('message.process_updated'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.process_update_error'))
    } finally {
      router.push({ name: 'organization-process-list' })
      isSubmitting.value = false
    }
  }

  return {
    update,
    show,
    item,
    isSubmitting,
  }
}
